import { DataFrame, PanelData } from '@grafana/data';

export const getData = (pData: PanelData) => {
  const { series } = pData;

  if (series.length === 0) {
    return [];
  }

  return series.map((s, sId) => getSeriesData(s, sId));
};

export const getSeriesData = (series: DataFrame, seriesID: number) => {
  const columns = series.fields.length;
  const rows = series.length;
  return { columns, rows, seriesID };
};

export const getHeaders = (pData: PanelData, seriesID: number) => {
  const { series } = pData;
  return series[0].fields.map((field) => field.name);
};

export const getRows = (pData: PanelData, seriesID: number) => {
  const { series } = pData;
  const { columns, rows } = getSeriesData(series[seriesID], seriesID);

  const rowData = new Array(rows).fill(0);
  rowData.forEach((row, index) => {
    rowData[index] = new Array(columns).fill('');
  });

  series[seriesID].fields.forEach((field, c) => {
    series[seriesID].fields[c].values.toArray().forEach((value, r) => {
      rowData[r][c] = value;
    });
  });

  return rowData;
};
