import { PanelData } from '@grafana/data';
import React from 'react';
import { CSVLink } from 'react-csv';
import { getHeaders, getRows } from 'util/customData';

const ExportCSV = ({ pData, seriesID }: { pData: PanelData; seriesID: number }) => {
  const buildCSVData = () => {
    const headers = getHeaders(pData, seriesID);
    const rowData = getRows(pData, seriesID);

    return [headers].concat(rowData);
  };

  return (
    <CSVLink
      className="csv-link"
      style={{
        border: '1px solid rgba(128,128,128,0.1)',
        borderRadius: 3,
        background: 'rgba(128,128,128,0.05)',
        padding: '5px 10px',
        marginLeft: 10,
      }}
      data={buildCSVData()}
    >
      Download CSV
    </CSVLink>
  );
};

export default ExportCSV;
