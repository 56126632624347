import React from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';
import { getData } from 'util/customData';
import ExportCSV from './ExportCSV';

interface Props extends PanelProps<SimpleOptions> {}

export class CustomExport extends React.Component<Props> {
  render() {
    const { data, options } = this.props;
    const queryTotals = getData(data);
    const status = data.state;

    return (
      <div
        className="rradar-export"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <p
          style={{
            color: status === 'Done' ? 'green' : status === 'Loading' ? 'orange' : status === 'Error' ? 'red' : 'grey',
          }}
        >
          <strong>{status}</strong>
        </p>
        {(options.showCounts || options.showExport) &&
          queryTotals.map((qt) => {
            return (
              <p key={qt.seriesID}>
                {options.showCounts && (
                  <span>
                    ID:{qt.seriesID} - <strong>{qt.rows}</strong>r x <strong>{qt.columns}</strong>c
                  </span>
                )}
                {options.showExport && <ExportCSV pData={data} seriesID={qt.seriesID} />}
              </p>
            );
          })}
      </div>
    );
  }
}
